<template>
  <div class="col-12 col-lg-7 c">
    <div class="card">
      <div class="card-header">
        <h4 class="text-center">
          <i class="fa fa-bell"></i>
          الاشعارات السابقة
        </h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label for="">اختر يوم معين</label>
          <input
            type="date"
            class="form-control"
            v-model="date"
            @change="get()"
          />
          <small id="helpId" class="form-text text-muted"
            >افتراضي يُعرض احدث 100 اشعار. قم باختيار اليوم لعرض كل
            اشعاراته</small
          >
        </div>
        <div class="col-12" v-for="noti in notis" :key="noti._id">
          <div class="card card-body border">
            <h5>{{ noti.content }}</h5>
            <p>{{ noti.date }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    var g = this;
    g.get();
  },
  data() {
    return {
      notis: [],
      user: JSON.parse(localStorage.getItem("user")),
      date: null,
    };
  },
  methods: {
    get() {
      var g = this;
      $.post(api + "/admin/noti", {
        jwt: g.user.jwt,
        date: g.date,
      })
        .then(function (r) {
          r = JSON.parse(r);
          g.notis = r;
        })
        .catch(function (r) {
          alert("حدث خطأ");
        });
    },
  },
};
</script>

<style>
</style>